.App {
    height: 100%;
    width: 100%;
}

.content {
    position: static;
    top: 90px;
    height: calc(100vh - 80px - 90px - 32px);
    overflow: auto;
}

.content-list {
    margin: 16px;
}

h2 {
    margin-top: 0px;
    margin-bottom: 4px
}

.date {
    font-size: medium;
}

.date_no-data {
    font-weight: 100;
    text-decoration: line-through;
}

table {
    margin: auto;
}

.footer-pagination {
    height: 32px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.footer {
    height: 80px;
    bottom: 0;
    position: static;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
}

.footer-greetings {
    margin-top: 8px;
    padding: 4px;
    font-size: small;
    text-align: center;
}

.header {
    height: 90px;
    position: static;
    top: 0;
    overflow: hidden;
}

.header-title {
    font-size: larger;
}

.settings {
    display: flex;
    height: 80px;
    margin:16px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}


body {
    margin: 0;
}

.content_loading {
    text-align: center;
}

.header-first-line {
    margin: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-second-line {
    margin: 0px;
    margin-right: 8px;
    display: flex;
    justify-content: right;
    align-items: center;
}

.day-styling {
    margin: 16px;
}

.day_with-appointments {
    margin: 8px;
    margin-bottom: 16px;
    padding: 4px
}